// router
import { BrowserRouter as Router} from 'react-router-dom';

// components
import Header from '../components/header/Header';
import Footer from '../components/Footer';

const Layout = ({ children }) => {
  return (
    <Router>
      <main>{children}</main>
      
    </Router>
  )
}

export default Layout;