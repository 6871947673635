import React from 'react';
import { useNavigate } from "react-router-dom";
import { Auth, Logger } from 'aws-amplify';


const XSignOut = () => {
  const navigate = useNavigate();
  const logger = new Logger('Xiltrix Portal', 'INFO');
  
  const signOut = async() => {
    Auth.signOut().then(
      () => {
        logger.info('Logged out...');
        window.location.reload(false);
      } 
    )
     .catch(err => {logger.error(err);});
  };

  return (
      <>
        <span light outline sm border="0" onClick={() => signOut()} >Sign Out</span>
      </>
  );
}

export default XSignOut;