
const Profile = () => {
  // page content

  return (
    <div>
      Profile
    </div>
  )
}

export default Profile;