/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "appstreamSessionApi",
            "endpoint": "https://kjdxp720w0.execute-api.us-west-2.amazonaws.com/main",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://vhkwt3tlnzairk7r2voat3s5ua.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-west-2:ae56fc07-4cc3-4950-ba4b-bd6f64095c31",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_nwU6Y5fHU",
    "aws_user_pools_web_client_id": "5t605oqjm8tcsjhkq1rf95j4kl",
    "oauth": {
        "domain": "x5customertest.auth.us-west-2.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
