import { Container } from 'react-bootstrap';
import HeaderNav from './Nav';

// Style
import './header.scss';

const Header = ({ head, description }) => {
  return (
      <header className='d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-1 mb-0'>
        <HeaderNav />  
      </header>
  )
}

export default Header;