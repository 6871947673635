import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Container from 'react-bootstrap/Container';
import logger from '../../Logger';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import XSignOut from "../auth/AuthSignOut";
import XilrtiXheaderlogo from '../../images/XilrtiX-header-logo.svg';

const HeaderNav = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState('');

  useEffect(() => {
    try {
      setError(null);
      setLoading(true);

      Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      }).then(user => {
        setUser(user);
      }).catch((err) => {
        logger.error(err);
        setError(err);
      });
    }
    catch (e) {
      logger.error(e);
      setError(e);
    }
    finally {
      setLoading(false);
    }
  }, []);

  return (
    <Container>
      <header className=''>
        <Navbar expand="lg">
          <Container>
            <Navbar.Brand href="/">
            <img
              alt=""
              src={XilrtiXheaderlogo}
              width="100"
              height="30"
              className="d-inline-block align-top"
            />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <NavDropdown title={user.username} id="basic-nav-dropdown">
                  <NavDropdown.Item>
                    <XSignOut />
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </Container>
  )
}

export default HeaderNav;