import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// Style
import './Footer.scss';
import footerimage from '../images/XiltriX-logo-white.png';

const Footer = () => {
    const year = new Date().getFullYear()
    return (
      <>
      <footer className='fixed-bottom site-footer'>

      <Container>
        <Row md="auto" className="justify-content-md-center">
          <Col>
            <img width="250" height="94" src={footerimage} alt="logo"></img>
          </Col>
          <Col>
          <p>
            <strong>
            <a href="https://safetynet.xiltrixusa.com/">Contact SafetyNet</a></strong><br />
            <strong><a href="https://xiltrix.atlassian.net/servicedesk/customer/portal/10">Submit Feedback</a></strong><br />
            &copy; {year} XiltriX All Rights Reserved</p>

          </Col>
        </Row>
      </Container>
      </footer>

      </>
    )
  };
  
  export default Footer;