import React, { useEffect } from 'react';

import { useNavigate } from "react-router-dom";

const Logout = () => {
    // page content
    const pageTitle = 'XiltriX USA | Logging out...';
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/logout', { replace: true });
        window.location.reload(false);
    });
  
    return (
      <div>
      </div>
    )
  }
  
export default Logout;