import React from 'react';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import awsExports from "./aws-exports";

// Style
import '@aws-amplify/ui-react/styles.css';
import './App.scss';

// Layout
import Layout from './layout/Layout';

// Components
import { Header } from "./components/auth/AuthAmplify";

// pages
import Profile from './pages/Profile';
import NotFound from './pages/404';
import X5client from './pages/x5client/x5client';
import Logout from './pages/Logout';

Amplify.configure(awsExports);



const App =() => {
  return (
      <>
        <Layout>
        <Routes>
          <Route path='/' element={<X5client />} exact />
          <Route path='/x5' element={<X5client />} exact />
          <Route path='/Profile' element={<Profile />} />
          <Route path='/logout' element={<Logout />} />
          <Route element={<NotFound />} />
        </Routes>
        </Layout>
      </>

  );
}

export default withAuthenticator(App, {
  components: {Header},
  signUpAttributes: ['email']
});
